<template>
  <div>
    <p class="p3 color-green-2 ml-2 mb-4">
      Registre a participação dos cooperados nas assembleias. Cadastre
      individualmente ou importe um arquivo com a lista completa de
      participantes para agilizar o processo. Apenas a primeira coluna do
      arquivo será registrada; certifique-se de que ela está preenchida com os
      CRMs dos participantes.
    </p>
    <b-card border-variant="light" class="custom-card">
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            Evento
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.name"
              :options="assemblyTitleOptions"
              :state="validateField('name')"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Data
          </b-col>
          <b-col>
            <date-picker
              datePickerId="date"
              :key="form.date"
              :dataValue="form.date"
              @datePickerChangedValue="onChangedDate"
              :required="true"
              :state="validateFieldStartDate('date')"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            INFORMAR&nbsp;CRM(S)&nbsp;PARTICIPANTES
          </b-col>
          <b-col>
            <b-form-tags
              input-id="tags-basic"
              placeholder="Adicione o(s) CRM(s)"
              v-model="form.participants"
            ></b-form-tags>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <UploadFile
              :key="formFileId"
              :multiple="false"
              :accept="
                'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              "
              @updateFile="eventToUpdateFile"
              :description="'Importar arquivo'"
              v-model.lazy.trim="formFile"
            >
            </UploadFile>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
    <b-button
      :disabled="loading"
      type="button"
      class="mt-3"
      variant="primary"
      @click="onSubmit"
    >
      Cadastrar
      <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
    </b-button>
    <success-modal
      ref="success-modal"
      description="Registro salvo com sucesso!"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="validate-crm-attention-modal"
      title="Atenção!"
      submitButtonText="Ok, entendi!"
      ref="attention-modal"
      :onAction="this.onAttentionConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          {{ alertValidationMessage }}
        </p>
      </template>
    </attention-modal>
  </div>
</template>
<script>
import DatePicker from "../../../../components/date-picker/DatePicker.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { addAssembly } from "../../../../services/assembly-management/assembly-management-service";
import {
  validateField,
  validateFieldStartDate,
} from "../../../../utils/validate/validate.js";

const DefaultForm = {
  name: "",
  date: null,
  participants: null,
};

export default {
  components: {
    SuccessModal,
    AttentionModal,
    DatePicker,
    UploadFile,
  },
  data() {
    return {
      form: { ...DefaultForm },
      formFile: [],
      formFileId: 0,
      validations: {},
      loading: false,
      assemblyTitleOptions: [
        {
          value: "",
          text: "Selecione",
        },
        {
          value: "AGO - Assembleia Geral Ordinária",
          text: "AGO - Assembleia Geral Ordinária",
        },
        {
          value: "AGE - Assembleia Geral Extraordinária",
          text: "AGE - Assembleia Geral Extraordinária",
        },
      ],
      validateField,
      validateFieldStartDate,
      formSubmited: false,
      alertValidationMessage: "",
    };
  },
  methods: {
    async onSubmit() {
      this.formSubmited = true;
      var canSubmit = this.validateForm();

      if (canSubmit) {
        this.loading = true;

        try {
          await addAssembly(this.form, this.formFile);
          const modal = this.$refs["success-modal"].$children[0];
          modal.show();
          this.form = { ...DefaultForm };
          this.formFile = [];
          this.formFileId++;
        } catch (e) {
          if (
            e.response &&
            e.response.data &&
            e.response.data.error_code &&
            e.response.data.error_code == "EXISTING_ASSEMBLY"
          ) {
            this.showValidationAlert(e.response.data.message);
          }
          console.log(e.response.data.message);
        } finally {
          this.formSubmited = false;
          this.loading = false;
        }
      }
    },
    eventToUpdateFile(file) {
      this.formFile = file;
    },
    onChangedDate(value) {
      this.form.date = value;
    },
    validateForm() {
      var fieldsFilled = this.requiredFieldsFilled();
      var crmsFilled = this.validateCrmFields();
      if (!crmsFilled) {
        this.showValidationAlert(
          "É necessário informar algum(s) CRM(s) ou selecionar um arquivo com os CRM(s)!!"
        );
        return false;
      }

      return fieldsFilled;
    },
    showValidationAlert(message) {
      this.alertValidationMessage = message;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    validateCrmFields() {
      return this.form.participants.length > 0 || this.formFile.length > 0;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onAttentionConfirm() {
      const modal = this.$refs["attention-modal"].$children[0];
      modal.hide();
    },
  },
};
</script>
